import {
  ALTERAR_TEMPLATE_EMAIL,
  ALTERAR_TEMPLATE_EMAIL_RESULT,
  ALTERAR_VINCULOS,
  ALTERAR_VINCULOS_RESULT,
  BUSCAR_CAIXAS_OPERADORES,
  BUSCAR_DISPOSITIVO_LOGS,
  BUSCAR_DISPOSITIVOS,
  BUSCAR_MOVIMENTACAO_CAIXAS,
  BUSCAR_NCMS,
  BUSCAR_NOTA,
  BUSCAR_NOTAS,
  BUSCAR_NOTAS_SITUACOES,
  BUSCAR_NOTIFICACOES_USUARIO,
  BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS,
  BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS_RESULT,
  BUSCAR_NOTIFICACOES_USUARIO_RESULT,
  BUSCAR_SUGESTOES,
  BUSCAR_SUGESTOES_PRODUTOS,
  BUSCAR_SUGESTOES_STATUS,
  BUSCAR_TEMPLATES_EMAIL,
  BUSCAR_PERFIS,
  BUSCAR_PERFIS_RESULT,
  BUSCAR_CATEGORIA,
  BUSCAR_CATEGORIA_RESULT,
  CADASTRAR_CATEGORIA,
  CADASTRAR_CATEGORIA_RESULT,
  CADASTRAR_NOTIFICACAO_RESULT,
  BUSCAR_PERGUNTA,
  BUSCAR_PERGUNTA_RESULT,
  CADASTRAR_PERGUNTA,
  CADASTRAR_PERGUNTA_RESULT,
  CAIXAS_OPERADORES,
  CREATE_SUGGESTION,
  DISPOSITIVO_LOGS,
  DISPOSITIVOS,
  DO_DELETE_CATEGORY,
  DO_DELETE_QUESTION,
  DO_EDIT_CATEGORY,
  DO_EDIT_QUESTION,
  EDIT_PROFILE,
  EDIT_SUGGESTION,
  LISTAR_FAQS,
  LISTAR_FAQS_RESULT,
  MARCAR_NOTIFICACAO_USUARIO_LIDA,
  MARCAR_NOTIFICACAO_USUARIO_LIDA_RESULT,
  MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA,
  MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA_RESULT,
  MOVIMENTACAO_CAIXAS,
  NCMS,
  NOTA,
  NOTAS,
  NOTAS_SITUACOES,
  NOTIFICACAO_USUARIO_WEBSOCKET,
  NOTIFICACAO_USUARIO_WEBSOCKET_RESULT,
  CREATE_NCM,
  REMOVE_NCM,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  SET_USER_PREFERENCE,
  BUSCAR_SUGESTAO,
  BUSCAR_SUGESTAO_RESULT,
  SUGESTAO,
  SUGESTAO_RESULT,
  SUGESTOES,
  SUGESTOES_PRODUTOS,
  SUGESTOES_STATUS,
  TEMPLATES_EMAIL,
  VIEW_SUGGESTION,
  EDIT_NCM,
  NCM,
  NCM_RESULT,
  BUSCAR_DRE,
  BUSCAR_DRE_RESULT,
  LIMPAR_DRE,
  LIMPAR_DRE_RESULT,
  BUSCAR_NCM_CATEGORIAS,
  NCM_CATEGORIAS,
  BUSCAR_NOTIFICACOES,
  RELATORIO_EXECUTAR_RESULT,
  RELATORIO_EXECUTAR,
  BUSCAR_USUARIOS_POR_EMPRESA,
  USUARIOS_POR_EMPRESA,
  BUSCAR_CONTAS_PAGAR,
  BUSCAR_CONTAS_PAGAR_RESULT,
  BUSCAR_CONTAS_PAGAR_HOJE,
  BUSCAR_CONTAS_PAGAR_HOJE_RESULT,
  BUSCAR_CONTAS_PAGAR_MES,
  BUSCAR_CONTAS_PAGAR_MES_RESULT,
  BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES,
  BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES_RESULT,
  BUSCAR_CONTAS_PAGAR_ATRASADOS,
  BUSCAR_CONTAS_PAGAR_ATRASADOS_RESULT,
  BUSCAR_EVOLUCAO_CONTAS_PAGAR,
  BUSCAR_EVOLUCAO_CONTAS_PAGAR_RESULT,
  BUSCAR_PLANO_CONTAS,
  BUSCAR_PLANO_CONTAS_RESULT,
  BUSCAR_FORNECEDORES_NOME_CNPJ,
  BUSCAR_FORNECEDORES_NOME_CNPJ_RESULT,
  CADASTRAR_LANCAMENTO,
  CADASTRAR_LANCAMENTO_RESULT,
  EDITAR_LANCAMENTO,
  EDITAR_LANCAMENTO_RESULT,
  REMOVER_LANCAMENTO,
  REMOVER_LANCAMENTO_RESULT,
  PAGAR_LANCAMENTO_RESULT,
  PAGAR_LANCAMENTO,
  ESTORNAR_PAGAMENTO_LANCAMENTO,
  ESTORNAR_PAGAMENTO_LANCAMENTO_RESULT,
  VIEW_PERFIL,
  EDIT_PERFIL,
  CREATE_PERFIL,
  CADASTRAR_PERFIL,
  CADASTRAR_PERFIL_RESULT,
  ALTERAR_PERFIL,
  ALTERAR_PERFIL_RESULT,
  REMOVER_PERFIL,
  REMOVER_PERFIL_RESULT,
  BUSCAR_PERFIL,
  BUSCAR_PERFIL_RESULT,
  BUSCAR_PERMISSAO_PERFIL,
  BUSCAR_PERMISSAO_PERFIL_RESULT,
  PERSISTIR_PERMISSOES,
  PERSISTIR_PERMISSOES_RESULT,
  BUSCAR_FORNECEDORES,
  BUSCAR_FORNECEDORES_RESULT,
  BUSCAR_FORNECEDOR,
  BUSCAR_FORNECEDOR_RESULT,
  CRIAR_FORNECEDOR,
  CRIAR_FORNECEDOR_RESULT,
  EDITAR_FORNECEDOR,
  EDITAR_FORNECEDOR_RESULT,
  REMOVER_FORNECEDOR_RESULT,
  REMOVER_FORNECEDOR,
  BUSCAR_QUANTIDADE_FORNECEDORES,
  BUSCAR_QUANTIDADE_FORNECEDORES_RESULT,
  NAVEGAR_EDITAR_FORNECEDOR,
  BUSCAR_QUANTIDADE_GRUPOS_PRODUTO,
  BUSCAR_GRUPO_PRODUTO_RESULT,
  BUSCAR_QUANTIDADE_GRUPOS_PRODUTO_RESULT,
  CRIAR_GRUPO_PRODUTO,
  BUSCAR_GRUPO_PRODUTO,
  BUSCAR_GRUPOS_PRODUTO_RESULT,
  BUSCAR_GRUPOS_PRODUTO,
  REMOVER_GRUPO_PRODUTO,
  NAVEGAR_EDITAR_GRUPO_PRODUTO,
  EDITAR_GRUPO_PRODUTO_RESULT,
  EDITAR_GRUPO_PRODUTO,
  CRIAR_GRUPO_PRODUTO_RESULT,
  REMOVER_GRUPO_PRODUTO_RESULT,
  BUSCAR_PRODUTOS,
  BUSCAR_PRODUTOS_RESULT,
  BUSCAR_PRODUTO,
  BUSCAR_PRODUTO_RESULT,
  BUSCAR_QUANTIDADE_PRODUTOS,
  BUSCAR_QUANTIDADE_PRODUTOS_RESULT,
  CRIAR_PRODUTO,
  CRIAR_PRODUTO_RESULT,
  EDITAR_PRODUTO,
  NAVEGAR_EDITAR_PRODUTO,
  REMOVER_PRODUTO,
  REMOVER_PRODUTO_RESULT,
  BUSCAR_GRUPOS_PRODUTO_POR_NOME,
  BUSCAR_GRUPOS_PRODUTO_POR_NOME_RESULT,
  BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO,
  BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO_RESULT,
  BUSCAR_CODIGOS_BARRAS,
  BUSCAR_CODIGOS_BARRAS_RESULT,
  CRIAR_CODIGO_BARRAS,
  CRIAR_CODIGO_BARRAS_RESULT,
  EDITAR_CODIGO_BARRAS,
  EDITAR_CODIGO_BARRAS_RESULT,
  REMOVER_CODIGO_BARRAS,
  REMOVER_CODIGO_BARRAS_RESULT,
  BUSCAR_CODIGOS_BARRAS_POR_PRODUTO,
  BUSCAR_CODIGOS_BARRAS_POR_PRODUTO_RESULT,
  BUSCAR_PRODUTOS_REFERENCIA,
  BUSCAR_PRODUTOS_REFERENCIA_RESULT,
  BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO,
  BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO_RESULT,
  CRIAR_PRODUTO_REFERENCIA,
  CRIAR_PRODUTO_REFERENCIA_RESULT,
  EDITAR_PRODUTO_REFERENCIA,
  EDITAR_PRODUTO_REFERENCIA_RESULT,
  REMOVER_PRODUTO_REFERENCIA,
  REMOVER_PRODUTO_REFERENCIA_RESULT,
  CRIAR_PRODUTO_COMPOSICAO,
  BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO_RESULT,
  CRIAR_PRODUTO_COMPOSICAO_RESULT,
  EDITAR_PRODUTO_COMPOSICAO,
  EDITAR_PRODUTO_COMPOSICAO_RESULT,
  REMOVER_PRODUTO_COMPOSICAO,
  REMOVER_PRODUTO_COMPOSICAO_RESULT,
  BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO,
  BUSCAR_PRODUTOS_COMPOSICAO_RESULT,
  BUSCAR_PRODUTOS_COMPOSICAO,
  BUSCAR_IMAGEM_PRODUTO,
  BUSCAR_IMAGEM_PRODUTO_RESULT,
  CRIAR_PRODUTO_MANTEM_CADASTRO,
  CRIAR_PRODUTO_MANTEM_CADASTRO_RESULT,
  BUSCAR_GRUPOS_CARDAPIO,
  BUSCAR_GRUPOS_CARDAPIO_RESULT,
  BUSCAR_GRUPO_CARDAPIO,
  BUSCAR_GRUPO_CARDAPIO_RESULT,
  BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO_RESULT,
  CRIAR_GRUPO_CARDAPIO,
  CRIAR_GRUPO_CARDAPIO_RESULT,
  CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO,
  CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO_RESULT,
  EDITAR_GRUPO_CARDAPIO,
  EDITAR_GRUPO_CARDAPIO_RESULT,
  NAVEGAR_EDITAR_GRUPO_CARDAPIO,
  REMOVER_GRUPO_CARDAPIO,
  REMOVER_GRUPO_CARDAPIO_RESULT,
  BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO,
  CRIAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
  CRIAR_GRUPO_CARDAPIO_OBSERVACAO,
  EDITAR_GRUPO_CARDAPIO_OBSERVACAO,
  EDITAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
  REMOVER_GRUPO_CARDAPIO_OBSERVACAO,
  REMOVER_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
  BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO_RESULT,
  BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO,
  BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_RESULT,
  BUSCAR_GRUPO_CARDAPIO_OBSERVACAO,
  BUSCAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
  BUSCAR_GRUPOS_CARDAPIO_ITEM,
  BUSCAR_GRUPOS_CARDAPIO_ITEM_RESULT,
  BUSCAR_GRUPO_CARDAPIO_ITEM,
  BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT,
  BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO,
  BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO_RESULT,
  BUSCAR_VENDAS_DIA,
  BUSCAR_VENDAS_MES,
  BUSCAR_VENDAS_MES_RESULT,
  BUSCAR_VENDAS_SEMANA_RESULT,
  BUSCAR_VENDAS_SEMANA,
  BUSCAR_VENDAS_DIA_RESULT,
  BUSCAR_VENDAS_MES_ANTERIOR,
  BUSCAR_VENDAS_MES_ANTERIOR_RESULT,
  BUSCAR_VENDAS_ANO_ANTERIOR_RESULT,
  BUSCAR_VENDAS_ANO_ANTERIOR,
  BUSCAR_VENDAS_ANO_RESULT,
  BUSCAR_VENDAS_ANO,
  BUSCAR_VENDAS_ANO_EVOLUCAO,
  BUSCAR_VENDAS_ANO_EVOLUCAO_RESULT,
  BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO,
  BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO_RESULT,
  BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS,
  BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS_RESULT,
  BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA_RESULT,
  BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA,
  BUSCAR_TAREFAS_POR_EMPRESA_RESULT,
  BUSCAR_TAREFAS_POR_EMPRESA,
  BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA,
  EDITAR_EMPRESA_RESULT,
  EDITAR_EMPRESA,
  BUSCAR_EMPRESA_RESULT,
  BUSCAR_EMPRESA,
  NAVEGAR_EDITAR_EMPRESA,
  BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA_RESULT,
  BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES,
  QUANTIDADE_SOLICITACOES_PENDENTES,
  BUSCAR_VINCULOS_POR_USUARIO,
  BUSCAR_VINCULOS_POR_USUARIO_RESULT,
  REJEITAR_VINCULOS_RESULT,
  REJEITAR_VINCULOS,
  EXPORTAR_USUARIO_CSV,
  EXPORTAR_USUARIO_CSV_RESULT,
  BUSCAR_EMPRESA_POR_USUARIO,
  BUSCAR_EMPRESA_POR_USUARIO_RESULT,
  BUSCAR_GRUPOS_ESTOQUE,
  BUSCAR_GRUPOS_ESTOQUE_RESULT,
  BUSCAR_GRUPOS_ESTOQUE_POR_NOME,
  BUSCAR_GRUPOS_ESTOQUE_POR_NOME_RESULT,
  BUSCAR_GRUPO_ESTOQUE,
  BUSCAR_GRUPO_ESTOQUE_RESULT,
  CRIAR_GRUPO_ESTOQUE,
  CRIAR_GRUPO_ESTOQUE_RESULT,
  EDITAR_GRUPO_ESTOQUE,
  EDITAR_GRUPO_ESTOQUE_RESULT,
  NAVEGAR_EDITAR_GRUPO_ESTOQUE,
  REMOVER_GRUPO_ESTOQUE,
  REMOVER_GRUPO_ESTOQUE_RESULT,
  CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO,
  CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO_RESULT,
  BUSCAR_ESTOQUES,
  NAVEGAR_EDITAR_ESTOQUE,
  REMOVER_ESTOQUE,
  EDITAR_ESTOQUE_RESULT,
  EDITAR_ESTOQUE,
  CRIAR_ESTOQUE_MANTEM_CADASTRO_RESULT,
  CRIAR_ESTOQUE_RESULT,
  CRIAR_ESTOQUE_MANTEM_CADASTRO,
  CRIAR_ESTOQUE,
  BUSCAR_ESTOQUE_RESULT,
  BUSCAR_ESTOQUE,
  BUSCAR_ESTOQUES_POR_NOME_RESULT,
  BUSCAR_ESTOQUES_POR_NOME,
  BUSCAR_ESTOQUES_RESULT,
  REMOVER_ESTOQUE_RESULT,
  BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE,
  BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE_RESULT,
  NAVEGAR_LISTAR_ESTOQUE,
  EDITAR_EMPRESA_CERTIFICADO_DIGITAL_RESULT,
  EDITAR_EMPRESA_CERTIFICADO_DIGITAL,
  REMOVER_EMPRESA_CERTIFICADO_DIGITAL,
  REMOVER_EMPRESA_CERTIFICADO_DIGITAL_RESULT,
  BUSCAR_EVOLUCAO_FISCAL,
  BUSCAR_EVOLUCAO_FISCAL_RESULT,
  EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO,
  EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO_RESULT,
  BUSCAR_NCM,
  BUSCAR_NCM_RESULT,
  BUSCAR_NOTIFICACOES_RESULT,
  BUSCAR_NOTIFICACAO,
  BUSCAR_NOTIFICACAO_RESULT,
  EDITAR_NOTIFICACAO,
  EDITAR_NOTIFICACAO_RESULT,
  REMOVER_NOTIFICACAO,
  REMOVER_NOTIFICACAO_RESULT,
  NAVEGAR_EDITAR_NOTIFICACAO,
  CRIAR_NOTIFICACAO,
  CRIAR_NOTIFICACAO_RESULT,
  BUSCAR_SINC,
  BUSCAR_SINC_RESULT,
  BUSCAR_TIPOS_EMPRESA,
  BUSCAR_TIPOS_EMPRESA_RESULT,
  BUSCAR_CONFIG_IFOOD_POR_EMPRESA,
  BUSCAR_CONFIG_IFOOD_POR_EMPRESA_RESULT,
  CRIAR_CONFIG_IFOOD_RESULT,
  CRIAR_CONFIG_IFOOD,
  EDITAR_CONFIG_IFOOD,
  EDITAR_CONFIG_IFOOD_RESULT
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user }
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error
  }
}

export const alterarVinculos = (vinculos, status, perfil) => {
  return {
    type: ALTERAR_VINCULOS,
    payload: { vinculos, status, perfil }
  }
}

export const alterarVinculosResult = (data, vinculos, status) => {
  return {
    type: ALTERAR_VINCULOS_RESULT,
    payload: { data, vinculos, status }
  }
}

export const buscarCategoria = (data, history) => {
  return {
    type: BUSCAR_CATEGORIA,
    payload: { data, history }
  }
}

export const buscarCategoriaResult = data => {
  return {
    type: BUSCAR_CATEGORIA_RESULT,
    payload: { data }
  }
}

export const cadastrarCategoria = (data, history) => {
  return {
    type: CADASTRAR_CATEGORIA,
    payload: { data, history }
  }
}

export const cadastrarCategoriaResult = (data, history) => {
  return {
    type: CADASTRAR_CATEGORIA_RESULT,
    payload: { data, history }
  }
}

export const buscarPergunta = (data, history) => {
  return {
    type: BUSCAR_PERGUNTA,
    payload: { data, history }
  }
}

export const buscarPerguntaResult = data => {
  return {
    type: BUSCAR_PERGUNTA_RESULT,
    payload: { data }
  }
}

export const cadastrarPergunta = (data, history) => {
  return {
    type: CADASTRAR_PERGUNTA,
    payload: { data, history }
  }
}

export const cadastrarPerguntaResult = data => {
  return {
    type: CADASTRAR_PERGUNTA_RESULT,
    payload: { data }
  }
}

export const listarFaqs = () => {
  return {
    type: LISTAR_FAQS,
    payload: {}
  }
}

export const listarFaqsResult = data => {
  return {
    type: LISTAR_FAQS_RESULT,
    payload: { data }
  }
}

export const doEditCategory = faqCategoria => {
  return {
    type: DO_EDIT_CATEGORY,
    payload: { faqCategoria }
  }
}

export const doDeleteCategory = data => {
  return {
    type: DO_DELETE_CATEGORY,
    payload: { data }
  }
}

export const doEditQuestion = faqPergunta => {
  return {
    type: DO_EDIT_QUESTION,
    payload: { faqPergunta }
  }
}

export const doDeleteQuestion = data => {
  return {
    type: DO_DELETE_QUESTION,
    payload: { data }
  }
}

export const buscarNotasSituacoes = () => {
  return {
    type: BUSCAR_NOTAS_SITUACOES,
    payload: {}
  }
}

export const notasSituacoes = data => {
  return {
    type: NOTAS_SITUACOES,
    payload: { data }
  }
}

export const buscarQuantidadeSolicitacoesPendentes = () => {
  return {
    type: BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES,
    payload: {}
  }
}

export const quantidadeSolicitacoesPendentes = data => {
  return {
    type: QUANTIDADE_SOLICITACOES_PENDENTES,
    payload: { data }
  }
}

export const buscarNotas = filters => {
  return {
    type: BUSCAR_NOTAS,
    payload: { filters }
  }
}

export const notas = data => {
  return {
    type: NOTAS,
    payload: { data }
  }
}

export const buscarNota = filters => {
  return {
    type: BUSCAR_NOTA,
    payload: { filters }
  }
}

export const nota = data => {
  return {
    type: NOTA,
    payload: { data }
  }
}

export const buscarSugestao = (data, history) => {
  return {
    type: BUSCAR_SUGESTAO,
    payload: { data, history }
  }
}

export const buscarSugestaoResult = data => {
  return {
    type: BUSCAR_SUGESTAO_RESULT,
    payload: { data }
  }
}

export const buscarSugestoes = () => {
  return {
    type: BUSCAR_SUGESTOES,
    payload: {}
  }
}

export const sugestoes = data => {
  return {
    type: SUGESTOES,
    payload: { data }
  }
}

export const sugestaoResult = data => {
  return {
    type: SUGESTAO_RESULT,
    payload: { data }
  }
}

export const buscarSugestoesStatus = () => {
  return {
    type: BUSCAR_SUGESTOES_STATUS,
    payload: {}
  }
}

export const sugestoesStatus = data => {
  return {
    type: SUGESTOES_STATUS,
    payload: { data }
  }
}

export const sugestao = (data, history) => {
  return {
    type: SUGESTAO,
    payload: { data, history }
  }
}

export const buscarSugestoesProdutos = () => {
  return {
    type: BUSCAR_SUGESTOES_PRODUTOS,
    payload: {}
  }
}

export const sugestoesProdutos = data => {
  return {
    type: SUGESTOES_PRODUTOS,
    payload: { data }
  }
}

export const doViewSuggestion = suggestion => {
  return {
    type: VIEW_SUGGESTION,
    payload: { suggestion }
  }
}

export const doEditSuggestion = suggestion => {
  return {
    type: EDIT_SUGGESTION,
    payload: { suggestion }
  }
}

export const doCreateSuggestion = () => {
  return {
    type: CREATE_SUGGESTION,
    payload: {}
  }
}

export const buscarMovimentacaoCaixas = filters => {
  return {
    type: BUSCAR_MOVIMENTACAO_CAIXAS,
    payload: { filters }
  }
}

export const movimentacaoCaixas = data => {
  return {
    type: MOVIMENTACAO_CAIXAS,
    payload: { data }
  }
}

export const buscarNCMs = filters => {
  return {
    type: BUSCAR_NCMS,
    payload: { filters }
  }
}

export const buscarNCMCategorias = filters => {
  return {
    type: BUSCAR_NCM_CATEGORIAS,
    payload: { filters }
  }
}

export const ncms = data => {
  return {
    type: NCMS,
    payload: { data }
  }
}

export const ncmCategorias = data => {
  return {
    type: NCM_CATEGORIAS,
    payload: { data }
  }
}

export const buscarCaixasOperadores = () => {
  return {
    type: BUSCAR_CAIXAS_OPERADORES,
    payload: {}
  }
}

export const caixasOperadores = data => {
  return {
    type: CAIXAS_OPERADORES,
    payload: { data }
  }
}

export const setUserPreference = (key, value) => {
  return {
    type: SET_USER_PREFERENCE,
    payload: { key, value }
  }
}

export const doCreateNCM = () => {
  return {
    type: CREATE_NCM,
    payload: {}
  }
}

export const doEditNCM = data => {
  return {
    type: EDIT_NCM,
    payload: { data }
  }
}

export const doRemoveNCM = data => {
  return {
    type: REMOVE_NCM,
    payload: { data }
  }
}

export const buscarNcm = (data) => {
  return {
    type: BUSCAR_NCM,
    payload: { data }
  }
}

export const buscarNcmResult = data => {
  return {
    type: BUSCAR_NCM_RESULT,
    payload: { data }
  }
}

export const ncm = (data, history) => {
  return {
    type: NCM,
    payload: { data, history }
  }
}

export const ncmResult = data => {
  return {
    type: NCM_RESULT,
    payload: { data }
  }
}

export const buscarNotificacoes = () => {
  return {
    type: BUSCAR_NOTIFICACOES,
    payload: {}
  }
}

export const buscarNotificacoesResult = data => {
  return {
    type: BUSCAR_NOTIFICACOES_RESULT,
    payload: { data }
  }
}

export const buscarNotificacao = (data, history) => {
  return {
    type: BUSCAR_NOTIFICACAO,
    payload: { data, history }
  }
}

export const buscarNotificacaoResult = data => {
  return {
    type: BUSCAR_NOTIFICACAO_RESULT,
    payload: { data }
  }
}

export const cadastrarNotificacao = (data, history) => {
  return {
    type: CRIAR_NOTIFICACAO,
    payload: { data, history }
  }
}

export const cadastrarNotificacaoResult = data => {
  return {
    type: CRIAR_NOTIFICACAO_RESULT,
    payload: { data }
  }
}

export const editarNotificacao = (data, history) => {
  return {
    type: EDITAR_NOTIFICACAO,
    payload: { data, history }
  }
}

export const editarNotificacaoResult = data => {
  return {
    type: EDITAR_NOTIFICACAO_RESULT,
    payload: { data }
  }
}

export const removerNotificacao = (data, history) => {
  return {
    type: REMOVER_NOTIFICACAO,
    payload: { data, history }
  }
}

export const removerNotificacaoResult = data => {
  return {
    type: REMOVER_NOTIFICACAO_RESULT,
    payload: { data }
  }
}

export const navegarEditarNotificacao = data => {
  return {
    type: NAVEGAR_EDITAR_NOTIFICACAO,
    payload: { data }
  }
}

export const buscarUsuariosPorEmpresa = filters => {
  return {
    type: BUSCAR_USUARIOS_POR_EMPRESA,
    payload: { filters }
  }
}

export const usuariosPorEmpresa = data => {
  return {
    type: USUARIOS_POR_EMPRESA,
    payload: { data }
  }
}

export const buscarDispositivos = data => {
  return {
    type: BUSCAR_DISPOSITIVOS,
    payload: { data }
  }
}

export const dispositivos = data => {
  return {
    type: DISPOSITIVOS,
    payload: { data }
  }
}

export const buscarDispositivoLogs = filters => {
  return {
    type: BUSCAR_DISPOSITIVO_LOGS,
    payload: { filters }
  }
}

export const dispositivoLogs = data => {
  return {
    type: DISPOSITIVO_LOGS,
    payload: { data }
  }
}

export const buscarTemplatesEmail = () => {
  return {
    type: BUSCAR_TEMPLATES_EMAIL,
    payload: {}
  }
}

export const templatesEmail = data => {
  return {
    type: TEMPLATES_EMAIL,
    payload: { data }
  }
}

export const alterarTemplateEmail = data => {
  return {
    type: ALTERAR_TEMPLATE_EMAIL,
    payload: { data }
  }
}

export const alterarTemplateEmailResult = data => {
  return {
    type: ALTERAR_TEMPLATE_EMAIL_RESULT,
    payload: { data }
  }
}

export const buscarNotificacoesUsuario = data => {
  return {
    type: BUSCAR_NOTIFICACOES_USUARIO,
    payload: { data }
  }
}

export const buscarNotificacoesUsuarioNaoLidas = () => {
  return {
    type: BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS,
    payload: {}
  }
}

export const marcarNotificacaoUsuarioLida = (data, exibeMensagem) => {
  return {
    type: MARCAR_NOTIFICACAO_USUARIO_LIDA,
    payload: { data, exibeMensagem }
  }
}

export const marcarNotificacaoUsuarioNaoLida = data => {
  return {
    type: MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA,
    payload: { data }
  }
}

export const buscarNotificacoesUsuarioResult = data => {
  return {
    type: BUSCAR_NOTIFICACOES_USUARIO_RESULT,
    payload: { data }
  }
}

export const buscarNotificacoesUsuarioNaoLidasResult = data => {
  return {
    type: BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS_RESULT,
    payload: { data }
  }
}

export const marcarNotificacaoUsuarioLidaResult = data => {
  return {
    type: MARCAR_NOTIFICACAO_USUARIO_LIDA_RESULT,
    payload: { data }
  }
}

export const marcarNotificacaoUsuarioNaoLidaResult = data => {
  return {
    type: MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA_RESULT,
    payload: { data }
  }
}

export const notificacaoUsuarioWebsocket = data => {
  return {
    type: NOTIFICACAO_USUARIO_WEBSOCKET,
    payload: { data }
  }
}

export const notificacaoUsuarioWebsocketResults = data => {
  return {
    type: NOTIFICACAO_USUARIO_WEBSOCKET_RESULT,
    payload: { data }
  }
}

export const buscarDRE = (
  vinculosId = null,
  periodStart = null,
  periodEnd = null,
  verticalAnalyze = null,
  horizontalAnalyze = null,
  type = null
) => {
  return {
    type: BUSCAR_DRE,
    payload: { vinculosId, periodStart, periodEnd, verticalAnalyze, horizontalAnalyze, type }
  }
}

export const buscarDREResult = data => {
  return {
    type: BUSCAR_DRE_RESULT,
    payload: { data }
  }
}

export const relatorioExecutar = data => {
  return {
    type: RELATORIO_EXECUTAR,
    payload: { data }
  }
}

export const relatorioExecutarResult = data => {
  return {
    type: RELATORIO_EXECUTAR_RESULT,
    payload: { data }
  }
}

export const limparDRE = () => {
  return {
    type: LIMPAR_DRE,
    payload: {}
  }
}

export const limparDREResult = data => {
  return {
    type: LIMPAR_DRE_RESULT,
    payload: { data }
  }
}

export const buscarContasPagar = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR,
    payload: { ...data }
  }
}

export const buscarContasPagarResult = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_RESULT,
    payload: { data }
  }
}

export const buscarContasPagarHoje = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_HOJE,
    payload: { data }
  }
}

export const buscarContasPagarHojeResult = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_HOJE_RESULT,
    payload: { data }
  }
}

export const buscarContasPagarMes = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_MES,
    payload: { data }
  }
}

export const buscarContasPagarMesResult = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_MES_RESULT,
    payload: { data }
  }
}

export const buscarContasPagarProximosMeses = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES,
    payload: { data }
  }
}

export const buscarContasPagarProximosMesesResult = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES_RESULT,
    payload: { data }
  }
}

export const buscarContasPagarAtrasados = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_ATRASADOS,
    payload: { data }
  }
}

export const buscarContasPagarAtrasadosResult = data => {
  return {
    type: BUSCAR_CONTAS_PAGAR_ATRASADOS_RESULT,
    payload: { data }
  }
}

export const buscarEvolucaoContasPagar = data => {
  return {
    type: BUSCAR_EVOLUCAO_CONTAS_PAGAR,
    payload: { data }
  }
}

export const buscarEvolucaoContasPagarResult = data => {
  return {
    type: BUSCAR_EVOLUCAO_CONTAS_PAGAR_RESULT,
    payload: { data }
  }
}

export const buscarPlanoContas = data => {
  return {
    type: BUSCAR_PLANO_CONTAS,
    payload: { data }
  }
}

export const buscarPlanoContasResult = data => {
  return {
    type: BUSCAR_PLANO_CONTAS_RESULT,
    payload: { data }
  }
}

export const buscarFornecedoresNomeCNPJ = data => {
  return {
    type: BUSCAR_FORNECEDORES_NOME_CNPJ,
    payload: { data }
  }
}

export const buscarFornecedoresNomeCNPJResult = data => {
  return {
    type: BUSCAR_FORNECEDORES_NOME_CNPJ_RESULT,
    payload: { data }
  }
}

export const buscarFornecedores = data => {
  return {
    type: BUSCAR_FORNECEDORES,
    payload: { ...data }
  }
}

export const buscarFornecedoresResult = data => {
  return {
    type: BUSCAR_FORNECEDORES_RESULT,
    payload: { data }
  }
}

export const buscarFornecedor = (data, history) => {
  return {
    type: BUSCAR_FORNECEDOR,
    payload: { data, history }
  }
}

export const buscarFornecedorResult = data => {
  return {
    type: BUSCAR_FORNECEDOR_RESULT,
    payload: { data }
  }
}

export const buscarQuantidadeFornecedores = data => {
  return {
    type: BUSCAR_QUANTIDADE_FORNECEDORES,
    payload: { data }
  }
}

export const buscarQuantidadeFornecedoresResult = data => {
  return {
    type: BUSCAR_QUANTIDADE_FORNECEDORES_RESULT,
    payload: { data }
  }
}

export const cadastrarFornecedor = (data, history) => {
  return {
    type: CRIAR_FORNECEDOR,
    payload: { data, history }
  }
}

export const cadastrarFornecedorResult = data => {
  return {
    type: CRIAR_FORNECEDOR_RESULT,
    payload: { data }
  }
}

export const editarFornecedor = (data, history) => {
  return {
    type: EDITAR_FORNECEDOR,
    payload: { data, history }
  }
}

export const editarFornecedorResult = data => {
  return {
    type: EDITAR_FORNECEDOR_RESULT,
    payload: { data }
  }
}

export const navegarEditarFornecedor = data => {
  return {
    type: NAVEGAR_EDITAR_FORNECEDOR,
    payload: { data }
  }
}

export const removerFornecedor = (data, history) => {
  return {
    type: REMOVER_FORNECEDOR,
    payload: { data, history }
  }
}

export const removerFornecedorResult = data => {
  return {
    type: REMOVER_FORNECEDOR_RESULT,
    payload: { data }
  }
}

export const cadastrarLancamento = data => {
  return {
    type: CADASTRAR_LANCAMENTO,
    payload: { data }
  }
}

export const cadastrarLancamentoResult = data => {
  return {
    type: CADASTRAR_LANCAMENTO_RESULT,
    payload: { data }
  }
}

export const editarLancamento = data => {
  return {
    type: EDITAR_LANCAMENTO,
    payload: { data }
  }
}

export const editarLancamentoResult = data => {
  return {
    type: EDITAR_LANCAMENTO_RESULT,
    payload: { data }
  }
}

export const removerLancamento = data => {
  return {
    type: REMOVER_LANCAMENTO,
    payload: { data }
  }
}

export const removerLancamentoResult = data => {
  return {
    type: REMOVER_LANCAMENTO_RESULT,
    payload: { data }
  }
}

export const pagarLancamento = data => {
  return {
    type: PAGAR_LANCAMENTO,
    payload: { data }
  }
}

export const pagarLancamentoResult = data => {
  return {
    type: PAGAR_LANCAMENTO_RESULT,
    payload: { data }
  }
}

export const estornarPagamentoLancamento = data => {
  return {
    type: ESTORNAR_PAGAMENTO_LANCAMENTO,
    payload: { data }
  }
}

export const estornarPagamentoLancamentoResult = data => {
  return {
    type: ESTORNAR_PAGAMENTO_LANCAMENTO_RESULT,
    payload: { data }
  }
}

export const buscarPerfis = data => {
  return {
    type: BUSCAR_PERFIS,
    payload: { data }
  }
}

export const buscarPerfisResult = data => {
  return {
    type: BUSCAR_PERFIS_RESULT,
    payload: { data }
  }
}

export const buscarPerfil = (data, history) => {
  return {
    type: BUSCAR_PERFIL,
    payload: { data, history }
  }
}

export const buscarPerfilResult = data => {
  return {
    type: BUSCAR_PERFIL_RESULT,
    payload: { data }
  }
}


export const doViewPerfil = data => {
  return {
    type: VIEW_PERFIL,
    payload: { data }
  }
}

export const doEditPerfil = data => {
  return {
    type: EDIT_PERFIL,
    payload: { data }
  }
}

export const doCreatePerfil = () => {
  return {
    type: CREATE_PERFIL,
    payload: {}
  }
}

export const cadastrarPerfil = (data, permissoes, history) => {
  return {
    type: CADASTRAR_PERFIL,
    payload: { data, permissoes, history }
  }
}

export const cadastrarPerfilResult = data => {
  return {
    type: CADASTRAR_PERFIL_RESULT,
    payload: { data }
  }
}

export const alterarPerfil = (data, permissoes, history) => {
  return {
    type: ALTERAR_PERFIL,
    payload: { data, permissoes, history }
  }
}

export const alterarPerfilResult = data => {
  return {
    type: ALTERAR_PERFIL_RESULT,
    payload: { data }
  }
}

export const removerPerfil = (data, history) => {
  return {
    type: REMOVER_PERFIL,
    payload: { data, history }
  }
}

export const removerPerfilResult = data => {
  return {
    type: REMOVER_PERFIL_RESULT,
    payload: { data }
  }
}

export const buscarPermissaoPerfil = (data) => {
  return {
    type: BUSCAR_PERMISSAO_PERFIL,
    payload: { data }
  }
}

export const buscarPermissaoPerfilResult = data => {
  return {
    type: BUSCAR_PERMISSAO_PERFIL_RESULT,
    payload: { data }
  }
}

export const persistirPermissoes = data => {
  return {
    type: PERSISTIR_PERMISSOES,
    payload: { data }
  }
}

export const persistirPermissoesResult = data => {
  return {
    type: PERSISTIR_PERMISSOES_RESULT,
    payload: { data }
  }
}

export const buscarGruposProduto = data => {
  return {
    type: BUSCAR_GRUPOS_PRODUTO,
    payload: { ...data }
  }
}

export const buscarGruposProdutoResult = data => {
  return {
    type: BUSCAR_GRUPOS_PRODUTO_RESULT,
    payload: { data }
  }
}

export const buscarGruposProdutoPorNome = data => {
  return {
    type: BUSCAR_GRUPOS_PRODUTO_POR_NOME,
    payload: { ...data }
  }
}

export const buscarGruposProdutoPorNomeResult = data => {
  return {
    type: BUSCAR_GRUPOS_PRODUTO_POR_NOME_RESULT,
    payload: { ...data }
  }
}

export const buscarGrupoProduto = (data, history) => {
  return {
    type: BUSCAR_GRUPO_PRODUTO,
    payload: { data, history }
  }
}

export const buscarGrupoProdutoResult = data => {
  return {
    type: BUSCAR_GRUPO_PRODUTO_RESULT,
    payload: { data }
  }
}

export const buscarQuantidadeGruposProduto = data => {
  return {
    type: BUSCAR_QUANTIDADE_GRUPOS_PRODUTO,
    payload: { data }
  }
}

export const buscarQuantidadeGruposProdutoResult = data => {
  return {
    type: BUSCAR_QUANTIDADE_GRUPOS_PRODUTO_RESULT,
    payload: { data }
  }
}

export const cadastrarGrupoProduto = (data, history) => {
  return {
    type: CRIAR_GRUPO_PRODUTO,
    payload: { data, history }
  }
}

export const cadastrarGrupoProdutoResult = data => {
  return {
    type: CRIAR_GRUPO_PRODUTO_RESULT,
    payload: { data }
  }
}

export const editarGrupoProduto = (data, history) => {
  return {
    type: EDITAR_GRUPO_PRODUTO,
    payload: { data, history }
  }
}

export const editarGrupoProdutoResult = data => {
  return {
    type: EDITAR_GRUPO_PRODUTO_RESULT,
    payload: { data }
  }
}

export const navegarEditarGrupoProduto = data => {
  return {
    type: NAVEGAR_EDITAR_GRUPO_PRODUTO,
    payload: { data }
  }
}

export const removerGrupoProduto = (data, history) => {
  return {
    type: REMOVER_GRUPO_PRODUTO,
    payload: { data, history }
  }
}

export const removerGrupoProdutoResult = data => {
  return {
    type: REMOVER_GRUPO_PRODUTO_RESULT,
    payload: { data }
  }
}

export const buscarProdutos = data => {
  return {
    type: BUSCAR_PRODUTOS,
    payload: { ...data }
  }
}

export const buscarProdutosResult = data => {
  return {
    type: BUSCAR_PRODUTOS_RESULT,
    payload: { data }
  }
}

export const buscarProduto = (data, history) => {
  return {
    type: BUSCAR_PRODUTO,
    payload: { data, history }
  }
}

export const buscarProdutoResult = data => {
  return {
    type: BUSCAR_PRODUTO_RESULT,
    payload: { data }
  }
}

export const buscarQuantidadeProdutos = data => {
  return {
    type: BUSCAR_QUANTIDADE_PRODUTOS,
    payload: { data }
  }
}

export const buscarQuantidadeProdutosResult = data => {
  return {
    type: BUSCAR_QUANTIDADE_PRODUTOS_RESULT,
    payload: { data }
  }
}

export const buscarImagemProduto = (data) => {
  return {
    type: BUSCAR_IMAGEM_PRODUTO,
    payload: { ...data }
  }
}

export const buscarImagemProdutoResult = data => {
  return {
    type: BUSCAR_IMAGEM_PRODUTO_RESULT,
    payload: { data }
  }
}

export const cadastrarProduto = (data, history) => {
  return {
    type: CRIAR_PRODUTO,
    payload: { data, history }
  }
}

export const cadastrarProdutoResult = data => {
  return {
    type: CRIAR_PRODUTO_RESULT,
    payload: { data }
  }
}

export const cadastrarProdutoMantemCadastro = (data, history) => {
  return {
    type: CRIAR_PRODUTO_MANTEM_CADASTRO,
    payload: { data, history, mantemCadastro: true }
  }
}

export const cadastrarProdutoMantemCadastroResult = data => {
  return {
    type: CRIAR_PRODUTO_MANTEM_CADASTRO_RESULT,
    payload: { data }
  }
}

export const editarProduto = (data, history) => {
  return {
    type: EDITAR_PRODUTO,
    payload: { data, history }
  }
}

export const editarProdutoResult = data => {
  return {
    type: EDITAR_GRUPO_PRODUTO_RESULT,
    payload: { data }
  }
}

export const navegarEditarProduto = data => {
  return {
    type: NAVEGAR_EDITAR_PRODUTO,
    payload: { data }
  }
}

export const removerProduto = (data, history) => {
  return {
    type: REMOVER_PRODUTO,
    payload: { data, history }
  }
}

export const removerProdutoResult = data => {
  return {
    type: REMOVER_PRODUTO_RESULT,
    payload: { data }
  }
}

export const buscarTabelaUnidadesPorUnidadeDescricao = (data, history) => {
  return {
    type: BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO,
    payload: { data, history }
  }
}

export const buscarTabelaUnidadesPorUnidadeDescricaoResult = data => {
  return {
    type: BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO_RESULT,
    payload: { data }
  }
}

export const buscarCodigosBarras = data => {
  return {
    type: BUSCAR_CODIGOS_BARRAS,
    payload: { ...data }
  }
}

export const buscarCodigosBarrasResult = data => {
  return {
    type: BUSCAR_CODIGOS_BARRAS_RESULT,
    payload: { data }
  }
}

export const buscarCodigosBarrasPorProduto = data => {
  return {
    type: BUSCAR_CODIGOS_BARRAS_POR_PRODUTO,
    payload: { ...data }
  }
}

export const buscarCodigosBarrasPorProdutoResult = data => {
  return {
    type: BUSCAR_CODIGOS_BARRAS_POR_PRODUTO_RESULT,
    payload: { data }
  }
}

export const cadastrarCodigoBarras = (data, history) => {
  return {
    type: CRIAR_CODIGO_BARRAS,
    payload: { data, history }
  }
}

export const cadastrarCodigoBarrasResult = data => {
  return {
    type: CRIAR_CODIGO_BARRAS_RESULT,
    payload: { data }
  }
}

export const editarCodigoBarras = (data, history) => {
  return {
    type: EDITAR_CODIGO_BARRAS,
    payload: { data, history }
  }
}

export const editarCodigoBarrasResult = data => {
  return {
    type: EDITAR_CODIGO_BARRAS_RESULT,
    payload: { data }
  }
}

export const removerCodigoBarras = (data, history) => {
  return {
    type: REMOVER_CODIGO_BARRAS,
    payload: { data, history }
  }
}

export const removerCodigoBarrasResult = data => {
  return {
    type: REMOVER_CODIGO_BARRAS_RESULT,
    payload: { data }
  }
}

export const buscarProdutosReferencia = data => {
  return {
    type: BUSCAR_PRODUTOS_REFERENCIA,
    payload: { ...data }
  }
}

export const buscarProdutosReferenciaResult = data => {
  return {
    type: BUSCAR_PRODUTOS_REFERENCIA_RESULT,
    payload: { data }
  }
}

export const buscarProdutosReferenciaPorProduto = data => {
  return {
    type: BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO,
    payload: { ...data }
  }
}

export const buscarProdutosReferenciaPorProdutoResult = data => {
  return {
    type: BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO_RESULT,
    payload: { data }
  }
}

export const cadastrarProdutoReferencia = (data, history) => {
  return {
    type: CRIAR_PRODUTO_REFERENCIA,
    payload: { data, history }
  }
}

export const cadastrarProdutoReferenciaResult = data => {
  return {
    type: CRIAR_PRODUTO_REFERENCIA_RESULT,
    payload: { data }
  }
}

export const editarProdutoReferencia = (data, history) => {
  return {
    type: EDITAR_PRODUTO_REFERENCIA,
    payload: { data, history }
  }
}

export const editarProdutoReferenciaResult = data => {
  return {
    type: EDITAR_PRODUTO_REFERENCIA_RESULT,
    payload: { data }
  }
}

export const removerProdutoReferencia = (data, history) => {
  return {
    type: REMOVER_PRODUTO_REFERENCIA,
    payload: { data, history }
  }
}

export const removerProdutoReferenciaResult = data => {
  return {
    type: REMOVER_PRODUTO_REFERENCIA_RESULT,
    payload: { data }
  }
}

export const buscarProdutosComposicao = data => {
  return {
    type: BUSCAR_PRODUTOS_COMPOSICAO,
    payload: { ...data }
  }
}

export const buscarProdutosComposicaoResult = data => {
  return {
    type: BUSCAR_PRODUTOS_COMPOSICAO_RESULT,
    payload: { data }
  }
}

export const buscarProdutosComposicaoPorProduto = data => {
  return {
    type: BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO,
    payload: { ...data }
  }
}

export const buscarProdutosComposicaoPorProdutoResult = data => {
  return {
    type: BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO_RESULT,
    payload: { data }
  }
}

export const cadastrarProdutoComposicao = (data, history) => {
  return {
    type: CRIAR_PRODUTO_COMPOSICAO,
    payload: { data, history }
  }
}

export const cadastrarProdutoComposicaoResult = data => {
  return {
    type: CRIAR_PRODUTO_COMPOSICAO_RESULT,
    payload: { data }
  }
}

export const editarProdutoComposicao = (data, history) => {
  return {
    type: EDITAR_PRODUTO_COMPOSICAO,
    payload: { data, history }
  }
}

export const editarProdutoComposicaoResult = data => {
  return {
    type: EDITAR_PRODUTO_COMPOSICAO_RESULT,
    payload: { data }
  }
}

export const removerProdutoComposicao = (data, history) => {
  return {
    type: REMOVER_PRODUTO_COMPOSICAO,
    payload: { data, history }
  }
}

export const removerProdutoComposicaoResult = data => {
  return {
    type: REMOVER_PRODUTO_COMPOSICAO_RESULT,
    payload: { data }
  }
}

export const buscarGruposCardapio = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO,
    payload: { ...data }
  }
}

export const buscarGruposCardapioResult = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_RESULT,
    payload: { data }
  }
}

export const buscarGrupoCardapio = (data, history) => {
  return {
    type: BUSCAR_GRUPO_CARDAPIO,
    payload: { data, history }
  }
}

export const buscarGrupoCardapioResult = data => {
  return {
    type: BUSCAR_GRUPO_CARDAPIO_RESULT,
    payload: { data }
  }
}

export const buscarQuantidadeGruposCardapio = data => {
  return {
    type: BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO,
    payload: { data }
  }
}

export const buscarQuantidadeGruposCardapioResult = data => {
  return {
    type: BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO_RESULT,
    payload: { data }
  }
}

export const cadastrarGrupoCardapio = (data, history) => {
  return {
    type: CRIAR_GRUPO_CARDAPIO,
    payload: { data, history }
  }
}

export const cadastrarGrupoCardapioResult = data => {
  return {
    type: CRIAR_GRUPO_CARDAPIO_RESULT,
    payload: { data }
  }
}

export const cadastrarGrupoCardapioMantemCadastro = (data, history) => {
  return {
    type: CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO,
    payload: { data, history, mantemCadastro: true }
  }
}

export const cadastrarGrupoCardapioMantemCadastroResult = data => {
  return {
    type: CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO_RESULT,
    payload: { data }
  }
}

export const editarGrupoCardapio = (data, history) => {
  return {
    type: EDITAR_GRUPO_CARDAPIO,
    payload: { data, history }
  }
}

export const editarGrupoCardapioResult = data => {
  return {
    type: EDITAR_GRUPO_CARDAPIO_RESULT,
    payload: { data }
  }
}

export const navegarEditarGrupoCardapio = data => {
  return {
    type: NAVEGAR_EDITAR_GRUPO_CARDAPIO,
    payload: { data }
  }
}

export const removerGrupoCardapio = (data, history) => {
  return {
    type: REMOVER_GRUPO_CARDAPIO,
    payload: { data, history }
  }
}

export const removerGrupoCardapioResult = data => {
  return {
    type: REMOVER_GRUPO_CARDAPIO_RESULT,
    payload: { data }
  }
}

export const buscarGruposCardapioObservacao = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO,
    payload: { ...data }
  }
}

export const buscarGruposCardapioObservacaoResult = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_RESULT,
    payload: { data }
  }
}

export const buscarGrupoCardapioObservacao = data => {
  return {
    type: BUSCAR_GRUPO_CARDAPIO_OBSERVACAO,
    payload: { ...data }
  }
}

export const buscarGrupoCardapioObservacaoResult = data => {
  return {
    type: BUSCAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    payload: { data }
  }
}

export const buscarGruposCardapioObservacaoPorGrupo = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO,
    payload: { ...data }
  }
}

export const buscarGruposCardapioObservacaoPorGrupoResult = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO_RESULT,
    payload: { data }
  }
}

export const cadastrarGrupoCardapioObservacao = (data, history) => {
  return {
    type: CRIAR_GRUPO_CARDAPIO_OBSERVACAO,
    payload: { data, history }
  }
}

export const cadastrarGrupoCardapioObservacaoResult = data => {
  return {
    type: CRIAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    payload: { data }
  }
}

export const editarGrupoCardapioObservacao = (data, history) => {
  return {
    type: EDITAR_GRUPO_CARDAPIO_OBSERVACAO,
    payload: { data, history }
  }
}

export const editarGrupoCardapioObservacaoResult = data => {
  return {
    type: EDITAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    payload: { data }
  }
}

export const removerGrupoCardapioObservacao = (data, history) => {
  return {
    type: REMOVER_GRUPO_CARDAPIO_OBSERVACAO,
    payload: { data, history }
  }
}

export const removerGrupoCardapioObservacaoResult = data => {
  return {
    type: REMOVER_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    payload: { data }
  }
}

export const buscarGruposCardapioItem = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_ITEM,
    payload: { ...data }
  }
}

export const buscarGruposCardapioItemResult = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_ITEM_RESULT,
    payload: { data }
  }
}

export const buscarGrupoCardapioItem = data => {
  return {
    type: BUSCAR_GRUPO_CARDAPIO_ITEM,
    payload: { ...data }
  }
}

export const buscarGrupoCardapioItemResult = data => {
  return {
    type: BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT,
    payload: { data }
  }
}

export const buscarGruposCardapioItemPorGrupo = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO,
    payload: { ...data }
  }
}

export const buscarGruposCardapioItemPorGrupoResult = data => {
  return {
    type: BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO_RESULT,
    payload: { data }
  }
}

export const buscarVendasDia = data => {
  return {
    type: BUSCAR_VENDAS_DIA,
    payload: { ...data }
  }
}

export const buscarVendasDiaResult = data => {
  return {
    type: BUSCAR_VENDAS_DIA_RESULT,
    payload: { data }
  }
}

export const buscarVendasSemana = data => {
  return {
    type: BUSCAR_VENDAS_SEMANA,
    payload: { ...data }
  }
}

export const buscarVendasSemanaResult = data => {
  return {
    type: BUSCAR_VENDAS_SEMANA_RESULT,
    payload: { data }
  }
}

export const buscarVendasMes = data => {
  return {
    type: BUSCAR_VENDAS_MES,
    payload: { ...data }
  }
}

export const buscarVendasMesResult = data => {
  return {
    type: BUSCAR_VENDAS_MES_RESULT,
    payload: { data }
  }
}

export const buscarVendasMesAnterior = data => {
  return {
    type: BUSCAR_VENDAS_MES_ANTERIOR,
    payload: { ...data }
  }
}

export const buscarVendasMesAnteriorResult = data => {
  return {
    type: BUSCAR_VENDAS_MES_ANTERIOR_RESULT,
    payload: { data }
  }
}

export const buscarVendasAno = data => {
  return {
    type: BUSCAR_VENDAS_ANO,
    payload: { ...data }
  }
}

export const buscarVendasAnoResult = data => {
  return {
    type: BUSCAR_VENDAS_ANO_RESULT,
    payload: { data }
  }
}

export const buscarVendasAnoAnterior = data => {
  return {
    type: BUSCAR_VENDAS_ANO_ANTERIOR,
    payload: { ...data }
  }
}

export const buscarVendasAnoAnteriorResult = data => {
  return {
    type: BUSCAR_VENDAS_ANO_ANTERIOR_RESULT,
    payload: { data }
  }
}

export const buscarVendasAnoEvolucao = data => {
  return {
    type: BUSCAR_VENDAS_ANO_EVOLUCAO,
    payload: { ...data }
  }
}

export const buscarVendasAnoEvolucaoResult = data => {
  return {
    type: BUSCAR_VENDAS_ANO_EVOLUCAO_RESULT,
    payload: { data }
  }
}

export const buscarVendasAnoEvolucaoPorAtendimento = data => {
  return {
    type: BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO,
    payload: { ...data }
  }
}

export const buscarVendasAnoEvolucaoPorAtendimentoResult = data => {
  return {
    type: BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO_RESULT,
    payload: { data }
  }
}

export const buscarVendasAnoEvolucaoPorStatus = data => {
  return {
    type: BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS,
    payload: { ...data }
  }
}

export const buscarVendasAnoEvolucaoPorStatusResult = data => {
  return {
    type: BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS_RESULT,
    payload: { data }
  }
}

export const buscarEmpresa = (data, history) => {
  return {
    type: BUSCAR_EMPRESA,
    payload: { data, history }
  }
}

export const buscarEmpresaResult = data => {
  return {
    type: BUSCAR_EMPRESA_RESULT,
    payload: { data }
  }
}

export const buscarEmpresaPorUsuario = data => {
  return {
    type: BUSCAR_EMPRESA_POR_USUARIO,
    payload: { data }
  }
}

export const buscarEmpresaPorUsuarioResult = data => {
  return {
    type: BUSCAR_EMPRESA_POR_USUARIO_RESULT,
    payload: { data }
  }
}

export const editarEmpresa = (data, history) => {
  return {
    type: EDITAR_EMPRESA,
    payload: { data, history }
  }
}

export const editarEmpresaResult = data => {
  return {
    type: EDITAR_EMPRESA_RESULT,
    payload: { data }
  }
}

export const editarEmpresaCertificadoDigital = (data, history) => {
  return {
    type: EDITAR_EMPRESA_CERTIFICADO_DIGITAL,
    payload: { data, history }
  }
}

export const editarEmpresaCertificadoDigitalResult = data => {
  return {
    type: EDITAR_EMPRESA_CERTIFICADO_DIGITAL_RESULT,
    payload: { data }
  }
}

export const removerEmpresaCertificadoDigital = (data, history) => {
  return {
    type: REMOVER_EMPRESA_CERTIFICADO_DIGITAL,
    payload: { data, history }
  }
}

export const removerEmpresaCertificadoDigitalResult = data => {
  return {
    type: REMOVER_EMPRESA_CERTIFICADO_DIGITAL_RESULT,
    payload: { data }
  }
}

export const navegarEditarEmpresa = data => {
  return {
    type: NAVEGAR_EDITAR_EMPRESA,
    payload: { data }
  }
}

export const buscarLicencasPorEmpresa = data => {
  return {
    type: BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA,
    payload: { ...data }
  }
}

export const buscarLicencasPorEmpresaResult = data => {
  return {
    type: BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA_RESULT,
    payload: { data }
  }
}

export const buscarTarefasPorEmpresa = data => {
  return {
    type: BUSCAR_TAREFAS_POR_EMPRESA,
    payload: { ...data }
  }
}

export const buscarTarefasPorEmpresaResult = data => {
  return {
    type: BUSCAR_TAREFAS_POR_EMPRESA_RESULT,
    payload: { data }
  }
}

export const buscarNotificacoesSistemaPorEmpresa = data => {
  return {
    type: BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA,
    payload: { ...data }
  }
}

export const buscarNotificacoesSistemaPorEmpresaResult = data => {
  return {
    type: BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA_RESULT,
    payload: { data }
  }
}

export const buscarVinculosPorUsuario = data => {
  return {
    type: BUSCAR_VINCULOS_POR_USUARIO,
    payload: { ...data }
  }
}

export const buscarVinculosPorUsuarioResult = data => {
  return {
    type: BUSCAR_VINCULOS_POR_USUARIO_RESULT,
    payload: { data }
  }
}

export const rejeitarVinculos = (data, history) => {
  return {
    type: REJEITAR_VINCULOS,
    payload: { data, history }
  }
}

export const rejeitarVinculosResult = (data, history) => {
  return {
    type: REJEITAR_VINCULOS_RESULT,
    payload: { data, history }
  }
}

export const exportarUsuarioCSV = (data) => {
  return {
    type: EXPORTAR_USUARIO_CSV,
    payload: { data }
  }
}

export const exportarUsuarioCSVResult = (data) => {
  return {
    type: EXPORTAR_USUARIO_CSV_RESULT,
    payload: { data }
  }
}

export const buscarGruposEstoque = data => {
  return {
    type: BUSCAR_GRUPOS_ESTOQUE,
    payload: { ...data }
  }
}

export const buscarGruposEstoqueResult = data => {
  return {
    type: BUSCAR_GRUPOS_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const buscarGruposEstoquePorNome = data => {
  return {
    type: BUSCAR_GRUPOS_ESTOQUE_POR_NOME,
    payload: { ...data }
  }
}

export const buscarGruposEstoquePorNomeResult = data => {
  return {
    type: BUSCAR_GRUPOS_ESTOQUE_POR_NOME_RESULT,
    payload: { ...data }
  }
}

export const buscarGrupoEstoque = (data, history) => {
  return {
    type: BUSCAR_GRUPO_ESTOQUE,
    payload: { data, history }
  }
}

export const buscarGrupoEstoqueResult = data => {
  return {
    type: BUSCAR_GRUPO_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const cadastrarGrupoEstoque = (data, history) => {
  return {
    type: CRIAR_GRUPO_ESTOQUE,
    payload: { data, history, mantemCadastro: false }
  }
}

export const cadastrarGrupoEstoqueResult = data => {
  return {
    type: CRIAR_GRUPO_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const cadastrarGrupoEstoqueMantemCadastro = (data, history) => {
  return {
    type: CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO,
    payload: { data, history, mantemCadastro: true }
  }
}

export const cadastrarGrupoEstoqueMantemCadastroResult = data => {
  return {
    type: CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO_RESULT,
    payload: { data }
  }
}

export const editarGrupoEstoque = (data, history) => {
  return {
    type: EDITAR_GRUPO_ESTOQUE,
    payload: { data, history }
  }
}

export const editarGrupoEstoqueResult = data => {
  return {
    type: EDITAR_GRUPO_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const navegarEditarGrupoEstoque = data => {
  return {
    type: NAVEGAR_EDITAR_GRUPO_ESTOQUE,
    payload: { data }
  }
}

export const navegarListarEstoque = data => {
  return {
    type: NAVEGAR_LISTAR_ESTOQUE,
    payload: { ...data }
  }
}

export const removerGrupoEstoque = (data, history) => {
  return {
    type: REMOVER_GRUPO_ESTOQUE,
    payload: { data, history }
  }
}

export const removerGrupoEstoqueResult = data => {
  return {
    type: REMOVER_GRUPO_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const buscarEstoques = data => {
  return {
    type: BUSCAR_ESTOQUES,
    payload: { ...data }
  }
}

export const buscarEstoquesResult = data => {
  return {
    type: BUSCAR_ESTOQUES_RESULT,
    payload: { data }
  }
}

export const buscarEstoquesPorNome = data => {
  return {
    type: BUSCAR_ESTOQUES_POR_NOME,
    payload: { ...data }
  }
}

export const buscarEstoquesPorNomeResult = data => {
  return {
    type: BUSCAR_ESTOQUES_POR_NOME_RESULT,
    payload: { ...data }
  }
}

export const buscarEstoque = (data, history) => {
  return {
    type: BUSCAR_ESTOQUE,
    payload: { data, history }
  }
}

export const buscarEstoqueResult = data => {
  return {
    type: BUSCAR_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const cadastrarEstoque = (data, history) => {
  return {
    type: CRIAR_ESTOQUE,
    payload: { data, history, mantemCadastro: false }
  }
}

export const cadastrarEstoqueResult = data => {
  return {
    type: CRIAR_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const cadastrarEstoqueMantemCadastro = (data, history) => {
  return {
    type: CRIAR_ESTOQUE_MANTEM_CADASTRO,
    payload: { data, history, mantemCadastro: true }
  }
}

export const cadastrarEstoqueMantemCadastroResult = data => {
  return {
    type: CRIAR_ESTOQUE_MANTEM_CADASTRO_RESULT,
    payload: { data }
  }
}

export const editarEstoque = (data, history) => {
  return {
    type: EDITAR_ESTOQUE,
    payload: { data, history }
  }
}

export const editarEstoqueResult = data => {
  return {
    type: EDITAR_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const navegarEditarEstoque = data => {
  return {
    type: NAVEGAR_EDITAR_ESTOQUE,
    payload: { data }
  }
}

export const removerEstoque = (data, history) => {
  return {
    type: REMOVER_ESTOQUE,
    payload: { data, history }
  }
}

export const removerEstoqueResult = data => {
  return {
    type: REMOVER_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const buscarEstoqueFilialPorEstoque = data => {
  return {
    type: BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE,
    payload: { ...data }
  }
}

export const buscarEstoqueFilialPorEstoqueResult = data => {
  return {
    type: BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE_RESULT,
    payload: { data }
  }
}

export const buscarEvolucaoFiscal = data => {
  return {
    type: BUSCAR_EVOLUCAO_FISCAL,
    payload: { ...data }
  }
}

export const buscarEvolucaoFiscalResult = data => {
  return {
    type: BUSCAR_EVOLUCAO_FISCAL_RESULT,
    payload: { data }
  }
}

export const editarEmpresaCertificadoDigitalSemAutenticacao = (data, history) => {
  return {
    type: EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO,
    payload: { data, history }
  }
}

export const enviarCertificadoDigitalResult = data => {
  return {
    type: EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO_RESULT,
    payload: { data }
  }
}

export const buscarSinc = (data) => {
  return {
    type: BUSCAR_SINC,
    payload: { data }
  }
}

export const buscarSincResult = data => {
  return {
    type: BUSCAR_SINC_RESULT,
    payload: { data }
  }
}

export const buscarTiposEmpresa = (data) => {
  return {
    type: BUSCAR_TIPOS_EMPRESA,
    payload: { data }
  }
}

export const buscarTiposEmpresaResult = data => {
  return {
    type: BUSCAR_TIPOS_EMPRESA_RESULT,
    payload: { data }
  }
}

export const buscarConfigIfoodPorEmpresa = data => {
  return {
    type: BUSCAR_CONFIG_IFOOD_POR_EMPRESA,
    payload: { ...data }
  }
}

export const buscarConfigIfoodPorEmpresaResult = data => {
  return {
    type: BUSCAR_CONFIG_IFOOD_POR_EMPRESA_RESULT,
    payload: { data }
  }
}

export const cadastrarConfigIfood = data => {
  return {
    type: CRIAR_CONFIG_IFOOD,
    payload: { data }
  }
}

export const cadastrarConfigIfoodResult = data => {
  return {
    type: CRIAR_CONFIG_IFOOD_RESULT,
    payload: { data }
  }
}

export const editarConfigIfood = data => {
  return {
    type: EDITAR_CONFIG_IFOOD,
    payload: { data }
  }
}

export const editarConfigIfoodResult = data => {
  return {
    type: EDITAR_CONFIG_IFOOD_RESULT,
    payload: { data }
  }
}


